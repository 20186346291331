/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Montserrat-Bold";
  src: url("/assets/font/montserrat/Montserrat-Bold.otf");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("/assets/font/montserrat/Montserrat-Medium.otf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("/assets/font/montserrat/Montserrat-SemiBold.otf");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("/assets/font/montserrat/Montserrat-Regular.otf");
}

@font-face {
  font-family: "Montserrat-Light";
  src: url("/assets/font/montserrat/Montserrat-Light.otf");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("/assets/font/montserrat/Montserrat-ExtraBold.otf");
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  src: url("/assets/font/montserrat/Montserrat-ExtraLight.otf");
}

.extra-light {
  font-family: Montserrat-ExtraLight;
}

.regular {
  font-family: Montserrat-Regular;
}

.medium {
  font-family: Montserrat-Medium;
}

.semi-bold {
  font-family: Montserrat-SemiBold;
}

.light {
  font-family: Montserrat-Light;
}

.extra-bold {
  font-family: Montserrat-ExtraBold;
}

.bold {
  font-family: Montserrat-Bold;
}

.fm-form-parent-header {
  font-size: 26px;
  font-family: "Montserrat-ExtraBold";
}

.fm-head-collapse {
  font-family: "Montserrat-Medium";
  font-size: 18px;
}

.f-100 {
  font-size: 10px;
}

.f-200 {
  font-size: 12px;
}

.f-400 {
  font-size: 14px;
}

.f-600 {
  font-size: 16px;
}

.f-800 {
  font-size: 18px;
}

.primary_background {
  background-color: #d0d0e0;
  height: 200vh;
}

.password-icon {
  height: 30px;
}

.export-icons {
  width: 22px;
  height: 26px;
}

.exports {
  border: 1px solid green;
  background-color: transparent;
  color: green;
  border-radius: 4px;
  font-size: 14px;
}

.enable-scroll {
  min-height: fit-content;
  max-height: 450px;
  overflow-y: scroll;
  padding: 10px;
}

.enable-scroll::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

.enable-scroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 2px #4C4175;  */
  border-radius: 10px;
}

.enable-scroll::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

.enable-scroll::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

.search-button-analytics {
  border-radius: 4px;
  background-color: green;
  border: none;
  color: #fff;
}

.clear-button-analytics {
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid red;
  color: red;
}

.arrow-forward {
  height: 22px;
}

.data-required-error {
  color: red;
  font-size: 10px;
}


.orientinsurance {
  --primary-color: #133F8A;
  --secondary-color: #1573F4;
  --third-color: #1573F4;
  // --button-primary:#1F29B1;
  --text-box-border: #C6CED7;
  --text-label: #84ABFE;
  --container-bg: #EAECF6;
  --vertical-primary-color: #133F8A;
  --text-side-panel: #6C70B6;
  --hover-shade:#EDF5FF;
  --side-panel-bg:#E6F0FF;
  
}


.default {
  --primary-color: #6C70B6;
  --secondary-color: #fa1066;
  --third-color: black;
  --text-label: #CFD6E9;
  --text-box-border: #C6CED7;
  --vertical-primary-color: #0150AA;
  --text-side-panel: #6C70B6;
  --hover-shade:#FFE6EF;
  --side-panel-bg:#F0F0F8;
}
.download{
  height: 100vh;
  font-size: 22px;
  color:  var(--secondary-color) !important;;
}
.download-excel {
  cursor: pointer;
  color:  var(--primary-color);
  font-size: 14px;
}
.heading {
  font-size: 20px;
  font-family: Montserrat-Bold;
  color: var(--secondary-color) !important;
}
.yes-box {
  width: 16px;
  height: 16px;
  background:  var(--secondary-color) !important;
  border-radius: 2px;
  opacity: 1;
}
.theme-primary-btn {
  background: transparent;
  border: 1px solid var(--secondary-color) !important;;
  border-radius: 8px;
  opacity: 1;
  color: var(--secondary-color) !important;;
  padding: 5px;
}

.cancel-buttons-popup {
  border: 1px solid var(--secondary-color) !important;
  background-color: transparent;
  border-radius: 5px;
  color: var(--secondary-color) !important;
}
.percentage-error {
  font-size: 12px;
  font-weight: 600;
  color:var(--secondary-color) !important;
}
.question{
  min-width: 200px;
}
.error{
  font-size: 12px;
  font-weight: 600;
  color:var(--secondary-color) !important;
}

.file-selector {
  position: absolute;
  z-index: 2;
  opacity: 0;
  left: 0px;
  top: 0px;
}
.add-benefits-button {
  background: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
  color: white;
  border-radius: 4px;
}

.login-button{
  width: 174px;
  height: 32px;
  background: var(--primary-color);
  border-radius: 17px; 
  text-align: center;
  font-size: 14px;
  vertical-align:baseline ;
  border :1px solid var(--primary-color);
  color: #ffFF !important;
 
}
.signuptext{
  letter-spacing: 0px;
  color: var(--primary-color);
  font-size: 14px;
  margin-top: 25px;
}

.upload {
  border: 1px solid  var(--primary-color);
  border-radius: 17px;
  color:  var(--primary-color);
  font-size: 14px;
  background-color: transparent;
}

.proceed {
  background:  var(--primary-color);
  border-radius: 17px;
  color: #fff;
  border: 1px solid  var(--primary-color);
}
.editQuote-button {
  background: green 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
  border: 1px solid  var(--primary-color);
  font-size: 14px;
  color: #ffff;
  cursor:pointer;
}


.vertical-line {
  position: absolute;
  border-left: 4px solid var(--vertical-primary-color);
  top: 2px;
  height: 100px;
  left: -4px;
}

.sortingKeyJourney{
  color: var(--secondary-color) !important;
  font-size: 18px;
  border-bottom: 2px solid  var(--secondary-color);
}

.exclusion {
  & .save-button {
      padding: 5px 35px;
      background: var(--secondary-color) 0% 0% no-repeat padding-box;
      border: 1px solid #c0c0c0;
      border-radius: 8px;
      opacity: 1;
      color: white;
      font-size: 16px;
    }
}

.create-broker-company {
  & .broker-company {
    color: var(--secondary-color);
  }

  & .create-button {
    border: 1px solid var(--secondary-color);
    background-color: transparent;
    color: var(--secondary-color);
    border-radius: 4px;
    font-size: 14px;
    padding: 6px;
  }
}

.home-text {
  background: var(--secondary-color);
  border: none;
  color: #ffff;
  border-radius: 5px;
}

.row-selector:hover{
  background:var(--hover-shade);

}

.analytics-header {
  font-size: 20px;
  color: #6C70B6;
}

.vertical-line-home{
  border-left: 4px solid var(--vertical-primary-color);
  height: 128px;
  position: absolute;
  left: 0px;
  top: 1px;
}

.next-button {
  background: var(--primary-color);
  border-radius: 17px;
  color: #fff;
  border: 2px solid var(--primary-color);
}

.download-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-color);
  border-radius: 17px;
  color: var(--primary-color);
  font-size: 14px;
}

.upload-button {
  background:var(--primary-color);
  border-radius: 17px;
  opacity: 1;
  border: 1px solid var(--primary-color);
  font-size: 14px;
  color: #ffff;
  cursor:pointer;
}

.ruleHeading {
  font-size: 18px;
  font-weight: 600;
  color: var(--secondary-color) !important; 
}
.errorMsg {
  font-size: 14px;
  font-weight: 600;
  color:var(--primary-color) !important;
}

.cancel-buttons {
  border: 1px solid var(--primary-color) !important;
  background-color: transparent;
  border-radius: 5px;
  color: var(--primary-color);
}

.optionBack {
  background:var(--primary-color);
  color: white;
}
.approve_button {
  background-color:  var(--secondary-color);
  color: white;
  text-align: center;
  // margin-left: 40px;
  border:none;
  // margin-right: 40px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 4px;
}
.download-pdf{
  background-color: var(--secondary-color);
  color: white;
  text-align: center;
  // margin-left: 40px;
  border:none;
  border-radius: 4px;
}

.send {
  border: 1px solid  var(--secondary-color);
  background-color:  var(--secondary-color);
  color: #FFFF;
  border-radius: 5px;
}

.nextFlowButton {
  box-shadow: 1px 1px 6px #00000029;
  border: 0.5px solid  var(--secondary-color);
  border-radius: 4px;
  color:  var(--secondary-color);
  background-color: transparent;
}

.workflowStatus {
  background: var(--hover-shade);
  border-radius: 2px;
  color: #233253;
}
.completed-workflow {
  background: #FFFFCE;
  border-radius: 2px;
}

.current-workFlow {
  background: var(--hover-shade);
  border-radius: 2px;
}
.version{
  color:var(--secondary-color);
}
  
.premium-count {
  border: 1px solid var(--secondary-color);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  color: var(--secondary-color);
}

.sendCustomer{
  background: var(--primary-color);
border-radius: 17px;
border: 1px solid  var(--primary-color);
color: #FFFFFF;
font-size: 14px;
}
.unselectCate {
border: 1px solid  var(--primary-color);
border-radius: 8px;
background-color: transparent;
color:  var(--primary-color);
}
.commission-label{
color:   var(--primary-color);
}
.total-label {
  text-align: center;
  font-size: 14px;
  font-family: Montserrat-Semibold;
  color:  var(--primary-color);
}
.selectCate {
  background-color:  var(--primary-color) !important;
  border-radius: 8px;
  border: none;
  color: white !important;
}

.aed{
  color: var(--third-color);
  font-size: 12px;
}

.totalAmount{
  color: var(--third-color);
  font-size: 16px;
}
.maf-color{
  color: var(--secondary-color);
  font-weight: 600;
}

.sideBar-panel{
  width: 84px;
  height: 93vh;
  background: var(--side-panel-bg);
  border-radius: 4px;
  position:fixed;
}
.logout{
  border: 0.5px solid  var(--primary-color);
  border-radius: 8px;
  background-color: transparent;
  color:  var(--primary-color);
  font-size: 12px;
  margin-left: -6px;
}
.title {
  color:var(--primary-color);
  font-size: 10px;
}

.submit-rivision{
  background:  var(--secondary-color);
  color: #FFF;
  border:none;
  border-radius: 4px;
}

.cancel{
  background:transparent;
  color: var(--secondary-color);
  border:1px solid  ;
  border-radius: 4px;
}
// .disable-edit-revision{
//   background: rgba($color: var(--secondary-color), $alpha: 0.6);
//   color: #FFF;
//   border:none;
//   border-radius: 4px;
// }

.approval_button {
  padding: 2px 0px;
  color:  var(--secondary-color);
  border: 2px solid  var(--secondary-color);
  background-color: #F5F5F9;
  border-radius: 5px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}
.upload-census{
  color: #FFFF;
  background:var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
  font-size: 14px;
  border-radius: 2px;
}
